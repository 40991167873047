jQuery(document).ready(function ($) {
    
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 80) {
            $('header').addClass('scroll');
        } else {
            $('header').removeClass('scroll');
        }
    });

    // Mobile toggle
    $('.header__toggle').click(function(e) {
        e.preventDefault();
        $('.header').toggleClass('header--mobile');
    });
    
});